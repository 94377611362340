import { Typography } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div className="PayStubForm">
            <div className="row myProgressBar">
                <div className="col-sm-12">
                    <h2 className="mb-3">Privacy Policy</h2>
                </div>
            </div>

            <div className="row progressBar" style={{ borderRadius: 10 }}>
                <Typography style={{ padding: 25 }}>
                    This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online.
                    PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or
                    locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use,
                    protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>
                        What personal information do we collect from the people that visit our blog, website or app?
                    </Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, credit card
                    information, social security number or other details to help you with your experience.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>When do we collect information?</Typography>
                </div>
                <Typography style={{ padding: 25 }}>We collect information from you when you place an order, fill out a form or enter information on our site.</Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>How do we use your information?</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication,
                    surf the website, or use certain other site features in the following ways:
                    <br /> • To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.
                    <br /> • To improve our website in order to better serve you.
                    <br /> • To allow us to better service you in responding to your customer service requests. <br />• To administer a contest, promotion, survey or other site
                    feature.
                    <br /> • To quickly process your transactions.
                    <br /> • To ask for ratings and reviews of services or products <br />• To follow up with them after correspondence (live chat, email or phone inquiries)
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>How do we protect your information?</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. We use
                    regular Malware Scanning.
                    <br /> Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to
                    such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket
                    Layer (SSL) technology.
                    <br /> We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your
                    personal information. <br />
                    All transactions are processed through a gateway provider and are not stored or processed on our servers.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>Do we use 'cookies'?</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    We do not use cookies for tracking purposes
                    <br /> You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your
                    browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies. If you turn cookies
                    off.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>Third-party disclosure</Typography>
                </div>
                <Typography style={{ padding: 25 }}>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>Third-party links</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    We do not include or offer third-party products or services on our website.
                    <br /> Google
                    <br /> Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users.
                    https://support.google.com/adwordspolicy/answer/1316548?hl=en We use Google AdSense Advertising on our website. Google, as a third-party vendor, uses cookies to
                    serve ads on our site. Google's use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the
                    Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.
                    <br /> We have implemented the following:
                    <br /> • Google Display Network Impression Reporting <br />• Demographics and Interests Reporting We, along with third-party vendors such as Google use
                    first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to
                    compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>Opting out:</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising
                    Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>California Online Privacy Protection Act</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond
                    California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable
                    Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals
                    or companies with whom it is being shared.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>According to CalOPPA, we agree to the following:</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    Users can visit our site anonymously. Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant
                    page after entering our website. Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above. You will be notified
                    of any Privacy Policy changes: <br />• On our Privacy Policy Page
                    <br /> Can change your personal information:
                    <br /> • By emailing us
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>How does our site handle Do Not Track signals?</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>Does our site allow third-party behavioral tracking?</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    It's also important to note that we allow third-party behavioral tracking <br />
                    COPPA (Children Online Privacy Protection Act)
                    <br /> When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA)
                    puts parents in control. The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of
                    websites and online services must do to protect children's privacy and safety online.
                    <br /> We do not specifically market to children under the age of 13 years old.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>
                        Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?
                    </Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    Fair Information Practices
                    <br /> The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant
                    role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is
                    critical to comply with the various privacy laws that protect personal information.
                    <br /> In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
                    <br /> We will notify you via email
                    <br /> • Within 1 business day
                    <br /> We will notify the users via in-site notification <br />• Within 1 business day <br />
                    We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors
                    and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that
                    individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.
                    <br /> CAN SPAM Act The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the
                    right to have emails stopped from being sent to them, and spells out tough penalties for violations.
                    <br /> We collect your email address in order to: <br />• Send information, respond to inquiries, and/or other requests or questions <br />• Process orders and
                    to send information and updates pertaining to orders. <br />• Send you additional information related to your product and/or service <br />• Market to our
                    mailing list or continue to send emails to our clients after the original transaction has occurred.
                    <br /> To be in accordance with CANSPAM, we agree to the following: <br />• Not use false or misleading subjects or email addresses.
                    <br /> • Identify the message as an advertisement in some reasonable way. • Include the physical address of our business or site headquarters. • Monitor
                    third-party email marketing services for compliance, if one is used. • Honor opt-out/unsubscribe requests quickly. <br />• Allow users to unsubscribe by using
                    the link at the bottom of each email.
                    <br /> If at any time you would like to unsubscribe from receiving future emails, you can email us at
                    <br /> • Follow the instructions at the bottom of each email.
                    <br /> and we will promptly remove you from ALL correspondence.
                    <br /> Contacting Us <br />
                    If there are any questions regarding this privacy policy, you may contact us using the information below. http://www.drpaystub.net/contact
                </Typography>

                <hr
                    style={{
                        color: 'black',
                        backgroundColor: 'black',
                        height: 1,
                        width: '100%',
                    }}
                />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button className="btn btn-secondary" style={{ width: '30%' }} href="./paystubs">
                        Create your Paystub now
                    </Button>
                </div>
            </div>
        </div>
    );
}
