/* eslint-disable no-restricted-imports */

import React, { Component } from "react";
import { connect } from "react-redux";
import "./dashboard.css";
import actionCreater from "../../../redux/actions/actionCreater";
import DashboardLayout from "./layout/DashboardLayout";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalUsers: 0,
      newUsers: 0,
    };
  }

  render() {
    return (
      <DashboardLayout title="">
        <div className="container">
          <div className="row myProgressBar">
            <div className="col-sm-12">
              <div className="row">
                <h6 className="dashHeaders">Statistics</h6>
                <div className="col-sm-6 col-6 col-md-6">
                  <p>Total Users: {this.state.totalUsers}</p>
                </div>
                <div className="col-sm-6 col-6 col-md-6">
                  <p>New Users: {this.state.newUsers}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps)(Dashboard);
