import React, { useEffect, useState } from 'react';
import Rating from './Rating';
import Cards from './Cards';
import { Typography } from '@material-ui/core';
import { Row } from 'react-bootstrap';
import { axios } from '../../../HelperFunctions/axios';

export default function Reviews() {
    const [reviews, setReviews] = useState([]);

    const addReviewCb = review => {
        getReview();
    };

    const getReview = async () => {
        const response = await axios.get('/api/reviews');
        setReviews(response.data.reviews);
    }

    useEffect(() => {
        getReview();
    }, []);

    return (
        <div className="PayStubForm">
            <div className="row myProgressBar">
                <div className="col-sm-12">
                    <h2 className="mb-3">Reviews</h2>
                </div>
            </div>

            <div className="row progressBar" style={{ borderRadius: 10 }}>
                <div className="col-sm-12">
                    <Rating addReviewCb={addReviewCb} />
                </div>

                <Typography className="row progressBar" style={{ fontFamily: 'sans-serif', fontStyle: 'italic', fontSize: 18, width: '50%' }}>
                    Here are some reviews that our clients gave us
                </Typography>

                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center', padding: 30 }}>
                    <Row>
                        <Cards reviews={reviews} />
                    </Row>
                </div>
            </div>
        </div>
    );
}
