import React, { Component } from "react";
import { Slide } from "react-reveal";
import "./Blogs.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import { axios } from "../../HelperFunctions/axios";
const format1 = "MM/DD/YYYY";

export class Blogs extends Component {
  state = {
    allBlogs: [],
  };
  componentDidMount() {
    axios
      .get("/api/blogs")
      .then((res) => {
        this.setState({ allBlogs: res.data.articles });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  allBlogsComponent = () => {
    let allBlogs = this.state.allBlogs.slice(0, 3).map((blog, bid) => {
      return (
        <div name="blog" className="mt-4 pt-2 col-md-6 col-lg-4">
          <div className="blog rounded border-0 shadow card">
            <div className="position-relative">
              <img
                src={blog.image ? blog.image.src : ""}
                alt=""
                className="rounded-top card-img-top"
              />
              <div className="overlay rounded-top bg-dark"></div>
            </div>
            <div className="content card-body">
              <h5>
                <Link
                  className="card-title title text-dark"
                  to={`/blogs/${blog.id}`}
                >
                  {blog.title}
                </Link>
              </h5>
              <div className="post-meta d-flex justify-content-between mt-3">
                <Link className="text-muted readmore" to={`/blogs/${blog.id}`}>
                  Read More <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div className="author">
              <small className="text-light user d-block">
                <i className="fa fa-user"></i> {blog.author}
              </small>
              <small className="text-light date">
                <i className="fa fa-calendar"></i>{" "}
                {blog.updated_at ? moment(blog.updated_at).format(format1) : ""}
              </small>
            </div>
          </div>
        </div>
      );
    });
    return allBlogs;
  };
  render() {
    return (
      <div className="container blogs">
        <div className="align-items-center mb-4 pb-2 row">
          <div className="col-lg-6">
            <Slide left>
              <div className="section-title text-center text-lg-left">
                <h6 className="text-primary">Blogs</h6>
                <h4 className="title mb-4 mb-lg-0">
                  Reads Our Latest <br /> News &amp; Blog
                </h4>
              </div>
            </Slide>
          </div>
          <div className="col-lg-6">
            <Slide right>
              <div className="section-title text-center text-lg-left">
                <p className="text-muted mb-0 mx-auto para-desc">
                  When it comes to taxation and proof of income updates,{" "}
                  <span className="text-primary font-weight-bold">
                    Dr.Paystub
                  </span>{" "}
                  blog is the answer. Find out the latest tips and tricks,
                  enhance your financial knowledge by browsing our articles.{" "}
                </p>
              </div>
            </Slide>
          </div>
        </div>
        <div className="row">{this.allBlogsComponent()}</div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <Slide bottom>
              <div className="text-center mt-5">
                <Link to="/blogs">
                  <button className="btn btn-secondary">Read more blogs</button>
                </Link>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    );
  }
}

export default Blogs;
