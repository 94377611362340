import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AC from "../../../redux/actions/actionCreater";
import { Carousel } from "react-responsive-carousel";
import { Button } from "react-bootstrap";
import "./PayStubForm.scss";
import { axios } from "../../../HelperFunctions/axios";
import animation from "../../../assets/loader.gif";
import { Checkout } from "./Checkout";
import Lottie from "react-lottie";
import loadingJson from "../../../assets/loading.json";
import { confirmAlert } from "react-confirm-alert";

const {
  REACT_APP_MODE,
  REACT_APP_FRONTEND_URL_LOCAL,
  REACT_APP_FRONTEND_URL_LIVE,
  REACT_APP_BACKEND_URL_LOCAL,
} = process.env;

function Step4(props) {
  const intial = {
    loaded: false,
    images: [],
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [index, setIndex] = useState(0);
  const [paystubCount, setPaystubCount] = useState(0);
  const [template, setTemplate] = useState(1);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [pageStatus, setPageStatus] = useState("pending");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(null);
  const [paystubId, setPaystubId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [templateImages1, setTemplateImages1] = useState([]);
  const [templateImages2, setTemplateImages2] = useState({ ...intial });
  const [templateImages3, setTemplateImages3] = useState({ ...intial });
  const [templateImages4, setTemplateImages4] = useState({ ...intial });
  const [templateImages5, setTemplateImages5] = useState({ ...intial });
  const [templateImages6, setTemplateImages6] = useState({ ...intial });
  const [currentImages, setCurrentImages] = useState([]);
  const [userData, setUserData] = useState();

  const componentRef = useRef();

  const handleGetUser = async () => {
    let url = process.env.REACT_APP_BACKEND_URL_LOCAL;
    if (process.env.REACT_APP_MODE === "live") {
      url = process.env.REACT_APP_FRONTEND_URL_LIVE;
    }
    const token = localStorage.getItem("tokens");
    const data = "";
    var config = {
      method: "get",
      url: `${url}api/auth/get-user`,
      headers: {
        Authorization: `bearer ${token}`,
      },
      data: data,
    };
    try {
      const response = await axios(config);
      setUserData(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleGetUser();
  }, []);

  const handleTemplateChange = (tem) => {
    setTemplate(tem);
    if (tem === 1) setCurrentImages(templateImages1);
    if (tem === 2) setCurrentImages(templateImages2.images);
    if (tem === 3) setCurrentImages(templateImages3.images);
    if (tem === 4) setCurrentImages(templateImages4.images);
    if (tem === 5) setCurrentImages(templateImages5.images);
    if (tem === 6) setCurrentImages(templateImages6.images);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      props.loadingFn(true);

      const _params = {
        ...props.step1,
        ...props.step2,
        ...props.step3,
        company_image: props.company_image,
        paymentStatus: "draft",
        userId: localStorage.getItem("userId"),
      };
      // const _params = {
      //   company_name: "Raftu",
      //   company_address: "#13",
      //   company_address_2: "Babu school",
      //   company_city: "Mohali",
      //   company_state: "",
      //   companyZipCode: "",
      //   company_phone: "123441232",
      //   company_ein: "33-3412312",
      //   emailAddress: "coco@yopmail.com",
      //   employment_status: "Hourly",
      //   employee_name: "Akhil sharma",
      //   employee_address: "#145,",
      //   employee_address_2: "Phase 8 ",
      //   employee_city: "Punjab",
      //   employee_state: "IOWA",
      //   employeeZipCode: "12121",
      //   employee_Id: "123123",
      //   maritial_status: "Single Taxpayers",
      //   noOfDependants: "",
      //   blindExemptions: "",
      //   ssid: "XXX-XX-1234",
      //   hourly_rate: "20",
      //   pay_frequency: "Weekly",
      //   hours_worked_per_payPeriod: "12",
      //   check_number: "321123",
      //   startDate: "16/06/2023",
      //   pay_dates: ["21/02/2023", "14/02/2023"],
      //   actual_pay_dates: ["25/02/2023", "19/02/2023"],
      //   hire_date: "28/02/2023",
      //   EmployeeHiredIn2021: false,
      //   additions: [],
      //   deductions: [],
      //   check_numbers: ["123123", "321123"],
      //   hours_worked: ["12", "31"],
      // };
      setEmail(_params.emailAddress);
      const paydates = _params.pay_dates.length;
      setPaystubCount(paydates);
      setAmount(15 * paydates);
      const { company_image, ...params } = _params;

      const formData = new FormData();
      formData.append("params", JSON.stringify(params));
      formData.append("company_image", company_image || null);

      const { data } = await axios.post("/api/paystub/save-stub", formData);
      if (data.status === false) {
        setPageStatus("error");
        setErrorMessage(data.message);
        return;
      }
      setPaystubId(data.paystub._id);
      getPaystubs(data.paystub._id);
    })();
  }, []);

  const getPaystubs = async (id) => {
    const { data } = await axios.post("/api/paystub/templates", {
      paystubId: id,
      template: 1,
    });
    if (data.status === false) {
      setPageStatus("error");
      setErrorMessage(data.message);
      return;
    }

    const paystubImagesOne = data.templates;
    setTemplateImages1(paystubImagesOne);
    setCurrentImages([...paystubImagesOne]);
    setPageStatus("show");

    const { data: temp2 } = await axios.post("/api/paystub/templates", {
      paystubId: id,
      template: 2,
    });
    const temp2images = temp2.templates;
    setTemplateImages2({
      loaded: true,
      images: temp2images,
    });

    const { data: temp3 } = await axios.post("/api/paystub/templates", {
      paystubId: id,
      template: 3,
    });
    const temp3images = temp3.templates;
    setTemplateImages3({
      loaded: true,
      images: temp3images,
    });

    const { data: temp4 } = await axios.post("/api/paystub/templates", {
      paystubId: id,
      template: 4,
    });
    const temp4images = temp4.templates;
    setTemplateImages4({
      loaded: true,
      images: temp4images,
    });

    const { data: temp5 } = await axios.post("/api/paystub/templates", {
      paystubId: id,
      template: 5,
    });
    const temp5images = temp5.templates;
    setTemplateImages5({
      loaded: true,
      images: temp5images,
    });

    const { data: temp6 } = await axios.post("/api/paystub/templates", {
      paystubId: id,
      template: 6,
    });
    const temp6images = temp6.templates;
    setTemplateImages6({
      loaded: true,
      images: temp6images,
    });
  };

  const downloadZip = () => {
    let url = REACT_APP_FRONTEND_URL_LOCAL;
    if (REACT_APP_MODE === "live") {
      url = REACT_APP_FRONTEND_URL_LIVE;
    }
    const redirectUrl = `${url}paystubs/success/${paystubId}?template=${template}`;
    window.location = redirectUrl;
  };

  return (
    <>
      <div className="PayStubForm formStep mt-3 text-center w-100">
        <div className="d-flex flex-column w-100">
          <form>
            <span className="badge badge-pill badge-soft-primary">Step 4</span>
            <h2>Preview Digital Payroll Checkstub</h2>
            <p className="text-muted">
              Choose your preferred template, revise your information and
              download your stub(s)
              <br />
              Note: All watermarks and background images will be removed from
              your final document(s)
            </p>
            <button
              type="button"
              className="mx-3  btn btn-outline-primary"
              onClick={() => {
                handleTemplateChange(1);
              }}
            >
              Template 1
            </button>
            <button
              type="button"
              className="mx-3  btn btn-outline-primary"
              disabled={!templateImages2.loaded}
              onClick={() => {
                handleTemplateChange(2);
              }}
            >
              {templateImages2.loaded ? "Template 2" : "Loading"}
            </button>
            <button
              type="button"
              className="mx-3  btn btn-outline-primary"
              disabled={!templateImages3.loaded}
              onClick={() => {
                handleTemplateChange(3);
              }}
            >
              {templateImages3.loaded ? "Template 3" : "Loading"}
            </button>
            <button
              type="button"
              className="mx-3  btn btn-outline-primary"
              disabled={!templateImages4.loaded}
              onClick={() => {
                handleTemplateChange(4);
              }}
            >
              {templateImages4.loaded ? "Template 4" : "Loading"}
            </button>
            <button
              type="button"
              className="mx-3  btn btn-outline-primary"
              disabled={!templateImages5.loaded}
              onClick={() => {
                handleTemplateChange(5);
              }}
            >
              {templateImages5.loaded ? "Template 5" : "Loading"}
            </button>
            <button
              type="button"
              className="mx-3  btn btn-outline-primary"
              disabled={!templateImages6.loaded}
              onClick={() => {
                handleTemplateChange(6);
              }}
            >
              {templateImages6.loaded ? "Template 6" : "Loading"}
            </button>
            <hr />
            {pageStatus === "show" && (
              <>
                <Carousel
                  renderIndicator={(
                    onClickHandler,
                    isSelected,
                    index,
                    label
                  ) => {
                    const indicatorStyles = {
                      background: "#fff",
                      width: 8,
                      height: 8,
                      display: "inline-block",
                      margin: "10px 8px",
                      border: "1px solid #000",
                    };
                    if (isSelected) {
                      return (
                        <li
                          style={{ ...indicatorStyles, background: "#000" }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                        />
                      );
                    }
                    return (
                      <li
                        style={indicatorStyles}
                        onClick={() => {
                          setIndex(index);
                          onClickHandler();
                        }}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        title={`${label} ${index + 1}`}
                        aria-label={`${label} ${index + 1}`}
                      />
                    );
                  }}
                >
                  {currentImages.map((el, idx) => (
                    <div className="row">
                      <div className="col-sm-12">
                        <img
                          style={{ width: "100%", objectFit: "contain" }}
                          src={`${
                            REACT_APP_MODE === "developement"
                              ? REACT_APP_BACKEND_URL_LOCAL
                              : "https://www.drpaystub.net/"
                          }${el}`}
                          parentRef={componentRef}
                          alt="Your Table is loading Please wait..."
                        />
                      </div>
                    </div>
                  ))}
                </Carousel>

                <p style={{ marginBottom: "0px" }}>
                  Stub(s) will be sent to the following email address
                </p>
                <p>
                  <strong>{email}</strong>
                </p>
                <div className="text-center mt-4">
                  {paymentStatus === "pending" ? (
                    <Checkout
                      paystubId={paystubId}
                      template={template}
                      amount={amount}
                      paydates={paystubCount}
                      setPaymentStatus={setPaymentStatus}
                    />
                  ) : (
                    <Button
                      onClick={downloadZip}
                      className="btn btn-secondary mt-4"
                    >
                      Click here to Download
                    </Button>
                  )}

                  <p className="text-muted mt-3">
                    <small>
                      Make sure your information is correct and continue to pay
                      and download your stub(s)
                    </small>
                  </p>
                </div>
              </>
            )}
            {pageStatus === "error" && (
              <h3 className="my-3" style={{ color: "red" }}>
                {errorMessage}
              </h3>
            )}
            {pageStatus === "pending" && (
              <>
                <Lottie options={defaultOptions} height={200} width={200} />

                <h3 className="my-5">
                  Digitizing Payroll Checkstub. Please be patient
                </h3>
              </>
            )}
          </form>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default connect((state) => state, {
  loadingFn: AC.loading,
  getUserDataSuccess: AC.getUserDataSuccess,
})(Step4);
