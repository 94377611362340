export default {
  STEP_1: "STEP_1",
  STEP_2: "STEP_2",
  STEP_3: "STEP_3",
  LOADING: "LOADING",
  STATE: "STATE",
  EMPLOYEMENT: "EMPLOYEMENT",
  PAID: "PAID",
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  PAY_FREQUENCY: "PAY_FREQUENCY",
  STEP_ONE: "STEP_ONE",
  STEP_TWO: "STEP_TWO",
  STEP_THREE: "STEP_THREE",
  RESPONSE: "RESPONSE",
  EXTRAS: "EXTRAS",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_PAYSTUB_DATA: "GET_PAYSTUB_DATA",
};
