import React, { Component } from "react";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { axios } from "../../../HelperFunctions/axios";
import DashboardLayout from "./layout/DashboardLayout";
import actionCreater from "../../../redux/actions/actionCreater";
import { connect } from "react-redux";
import placeholder from "../../../assets/placeholder.jpg";
import "../Dashboard/layout/styles.css";
import Table from "../Table/Table";

class Paystub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.handleGetPaystubs();
  }

  handleImageClick = () => {
    this.fileInput.click();
  };

  handleFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  handleGetPaystubs = async () => {
    let url = process.env.REACT_APP_BACKEND_URL_LOCAL;
    if (process.env.REACT_APP_MODE === "live") {
      url = process.env.REACT_APP_FRONTEND_URL_LIVE;
    }

    var data = "";

    const token = localStorage.getItem("tokens");
    var config = {
      method: "get",
      url: `${url}api/auth/get-paystubs`,
      headers: {
        Authorization: `bearer ${token}`,
      },
      data: data,
    };
    try {
      const response = await axios(config);
      this.props.getPaystubData(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { paystubData } = this.props;
    console.log(paystubData, "paystubData");
    return (
      <DashboardLayout>
        <div className="container h-100">
          <h2>List of paystubs</h2>
          <div className="row">
            <Table
              headings={[
                "S.No.",
                "Company Name",
                "Employee Name",
                "Email",
                "Date",
                "No. of Paystubs",
                "Amount",
                "Action",
              ]}
              data={paystubData ?? []}
            />
          </div>
        </div>
      </DashboardLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  paystubData: state?.paystubData,
});

export default connect(mapStateToProps, {
  getPaystubData: actionCreater.getPaystubData,
})(Paystub);
