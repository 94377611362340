import React, { Component } from "react";
import { axios } from "../HelperFunctions/axios";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";
import actionCreater from "../redux/actions/actionCreater";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./LoginForm.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Spinner } from "react-bootstrap";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      email: "",
      password: "",
      loading: false,
      showPassword: false,
    };
  }

  handleTogglePassword = () => {
    // Toggle the state to show/hide the password
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    try {
      let url = process.env.REACT_APP_BACKEND_URL_LOCAL;
      if (process.env.REACT_APP_MODE === "live") {
        url = process.env.REACT_APP_FRONTEND_URL_LIVE;
      }
      const payload = {
        email: this.state.email,
        password: this.state.password,
      };
      const response = await axios.post(`${url}api/auth/login`, payload);

      if (response.data.status === 200) {
        this.props.getUserDataSuccess(response.data.data);

        // confirmAlert({
        //   title: response.data.message,
        // });

        localStorage.setItem("tokens", response.data.tokens);
        localStorage.setItem("userId", response.data.data._id);

        this.props.history.push("/");
    
      } else if (response.data.message === "Please verify your email!") {
        confirmAlert({
          title: response.data.message,
          buttons: [
            {
              label: "Close",
              onClick: this.props.history.push(
                `/verify-email?email=${payload.email}`
              ),
            },
          ],
        });
        localStorage.removeItem("tokens");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
      } else {
        confirmAlert({
          title: response.data.message,
          buttons: [
            {
              label: "Close",
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }

    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="contact">
        <div className="container h-100">
          <div className="row align-items-center h-100 justify-content-center">
            <div className="col-md-8 col-lg-8 col-xl-5">
              <form className="contact-form" onSubmit={this.handleSubmit}>
                <h1>Please login to your account</h1>

                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="Your email"
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="passworddiv">
                    <input
                      type={this.state.showPassword ? "text" : "password"} // Conditionally set the input type
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      placeholder="Your Password"
                      required
                    />
                    <span
                      onClick={this.handleTogglePassword}
                      className="password-toggle-icon"
                    >
                      {this.state.showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                <div className="text-center">
                  {this.state.loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div>
                      {localStorage.getItem("clickStartAstrosync") ? (
                        <button
                          type="submit"
                          className="btn btn-secondary btn-block"
                          onClick={() => this.props.history.push("/paystubs")}
                        >
                          Login account
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-secondary btn-block"
                        >
                          Login account
                        </button>
                      )}
                    </div>
                  )}
                </div>
                {/* <div>
                  <button type="submit" className="btn btn-secondary btn-block">
                    Login account
                  </button>
                </div> */}
                <br />
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Link
                      to="/register"
                      className="forget-link dark semi-bold f-14 mb-3"
                    >
                      Sign up
                    </Link>
                  </div>
                  <div className="col-12 col-md-6 skipbutton22">
                    {localStorage.getItem("clickStartAstrosync") && (
                      <Link
                        to="/paystubs"
                        className="forget-link dark semi-bold f-14 mb-3"
                      >
                        Skip
                      </Link>
                    )}
                  </div>
                </div>
                <div>
                  <Link
                    to="/forgot-password"
                    className="forget-link dark semi-bold f-14 mb-3"
                  >
                    Forgot password
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state?.userData,
});

export default connect(mapStateToProps, {
  getUserDataSuccess: actionCreater.getUserDataSuccess,
})(LoginForm);
