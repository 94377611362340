import React, { useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { axios } from "../../../HelperFunctions/axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const {
  REACT_APP_MODE,
  REACT_APP_FRONTEND_URL_LOCAL,
  REACT_APP_FRONTEND_URL_LIVE,
} = process.env;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CheckoutForm = ({ paystubId, template, secret }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Demo total",
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        console.log(result);

        if (result) {
          setPaymentRequest(pr);

          pr.on("paymentmethod", async (ev) => {
            // Confirm the PaymentIntent without handling potential next actions (yet).
            const { paymentIntent, error: confirmError } =
              await stripe.confirmCardPayment(
                secret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
              );

            if (confirmError) {
              // Report to the browser that the payment failed, prompting it to
              // re-show the payment interface, or show an error message and close
              // the payment interface.
              ev.complete("fail");
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete("success");
              // Check if the PaymentIntent requires any actions and if so let Stripe.js
              // handle the flow. If using an API version older than "2019-02-11"
              // instead check for: `paymentIntent.status === "requires_source_action"`.
              if (paymentIntent.status === "requires_action") {
                // Let Stripe.js handle the rest of the payment flow.
                const { error } = await stripe.confirmCardPayment(secret);
                if (error) {
                  // The payment failed -- ask your customer for a new payment method.
                } else {
                  // The payment has succeeded.
                }
              } else {
                // The payment has succeeded.
              }
            }
          });
        }
      });
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    setLoading(true);

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    let url = REACT_APP_FRONTEND_URL_LOCAL;
    if (REACT_APP_MODE === "live") {
      url = REACT_APP_FRONTEND_URL_LIVE;
    }
    const redirectUrl = `${url}paystubs/success/${paystubId}?template=${template}`;
    console.log(redirectUrl);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setMessage(result.error.message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setLoading(false);
  };

  return (
    <>
      <div className="my-4">
        {/* {paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )} */}
      </div>
      <PaymentElement
        options={{
          fields: {
            billingDetails: {
              name: "auto",
              email: "auto",
            },
          },
        }}
      />
      <button
        disabled={!stripe || loading}
        type="button"
        className="btn btn-secondary mt-4 w-100"
        onClick={handleSubmit}
      >
        {loading && (
          <img
            className="pr-2"
            src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif"
            width="30"
          />
        )}
        Submit
      </button>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        key="bottomcenter"
      >
        <Alert severity="error">{message}</Alert>
      </Snackbar>
    </>
  );
};

export default CheckoutForm;
