import React, { Component } from "react";
import { axios } from "../HelperFunctions/axios";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Spinner } from "react-bootstrap";

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
      loading: false,
      showPassword: false,
    };
  }

  handleTogglePassword = () => {
    // Toggle the state to show/hide the password
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    try {
      let url = process.env.REACT_APP_BACKEND_URL_LOCAL;
      if (process.env.REACT_APP_MODE === "live") {
        url = process.env.REACT_APP_FRONTEND_URL_LIVE;
      }
      const payload = {
        email: this.state.email,
        password: this.state.password,
        phoneNumber: this.state.phoneNumber,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      };
      const response = await axios.post(`${url}api/auth/register`, payload);
      if (response.data.status === 200) {
        this.props.history.push(`/verify-email?email=${payload.email}`);

        confirmAlert({
          title: response.data.message,
          buttons: [
            {
              label: "Close",
            },
          ],
        });
      } else {
        confirmAlert({
          title: response.data.message,
          buttons: [
            {
              label: "Close",
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }

    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="contact">
        <div className="container h-100">
          <div className="row align-items-center h-100 justify-content-center">
            <div className="col-md-8 col-lg-8 col-xl-5">
              <form className="contact-form" onSubmit={this.handleSubmit}>
                <h1>Ready to get started?</h1>

                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    placeholder="First Name"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    placeholder="Last Name"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="Email"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={(e) =>
                      this.setState({ phoneNumber: e.target.value })
                    }
                    placeholder="Phone number"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>

                  <div className="passworddiv">
                    <input
                      type={this.state.showPassword ? "text" : "password"} // Conditionally set the input type
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      placeholder="Your Password"
                      required
                    />
                    <span
                      onClick={this.handleTogglePassword}
                      className="password-toggle-icon"
                    >
                      {this.state.showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>

                <div className="text-center">
                  {this.state.loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-secondary btn-block"
                    >
                      Submit
                    </button>
                  )}
                </div>
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterForm;
