import React from "react";
import { Link, withRouter } from "react-router-dom";
import { FaUser, FaCog } from "react-icons/fa";
import './styles.css'

class DashboardLayout extends React.Component {
  render() {
    const { location } = this.props;

    return (
      <div className="PayStubForm">
        <div className="container-fluid">
          <div className="row flex-nowrap">
            <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
              <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <ul
                  className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                  id="menu"
                >
                  <br />
                  <li>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/profile"
                        className={`nav-link collapsed text-truncate ${
                          location.pathname === "/dashboard/profile"
                            ? "active"
                            : ""
                        }`}
                      >
                        <i
                          class="fa fa-user"
                          style={{ marginRight: "8px", color: "white" }}
                        ></i>
                        <span
                          className="d-none d-sm-inline"
                          style={{ color: "white" }}
                        >
                          User Profile
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/change-password"
                        className={`nav-link collapsed text-truncate ${
                          location.pathname === "/dashboard/change-password"
                            ? "active"
                            : ""
                        }`}
                      >
                        <i
                          class="fa fa-user"
                          style={{ marginRight: "8px", color: "white" }}
                        ></i>
                        <span
                          className="d-none d-sm-inline"
                          style={{ color: "white" }}
                        >
                          Password Settings
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/paystub"
                        className={`nav-link collapsed text-truncate ${
                          location.pathname === "/dashboard/paystub"
                            ? "active"
                            : ""
                        }`}
                      >
                        <FaCog style={{ marginRight: "8px", color: "white" }} />
                        <span
                          className="d-none d-sm-inline"
                          style={{ color: "white" }}
                        >
                          Paystub
                        </span>
                      </Link>
                    </li>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col py-3">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardLayout);
