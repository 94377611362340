import React, { Component } from 'react';
import AboutImg from '../../assets/img/about1.png';
import { Zoom, Reveal, Slide } from 'react-reveal';
import './HowItWorks.scss';
import HowItWorks1 from '../../assets/img/howitworks-1.jpg';
import HowItWorks2 from '../../assets/img/howitworks-2.jpg';
import HowItWorks3 from '../../assets/img/howitworks-3.jpg';

export class HowItWorks extends Component {
    render() {
        return (
            <div className="container howItWorks">
                <div className="row">
                    <div className="col-sm-12">
                        <Slide bottom>
                            <h1 className="text-center">How it works?</h1>
                        </Slide>
                    </div>
                </div>

                {/* <div className="align-items-center mt-5 row">
          <div className="col-md-4 col-lg-4">
            <div className="card border-dark mb-3">
              <div className="card-header">Enter your information</div>
              <div className="card-body text-dark">
                <h5 className="card-title">Dark card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4"> */}
                {/* <img src={AboutImg} className="img-fluid" alt=""/> */}
                {/* <div className="card border-dark mb-3">
              <div className="card-header">Preview your stub</div>
              <div className="card-body text-dark">
                <h5 className="card-title">Dark card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="card border-dark mb-3">
              <div className="card-header">Download your stub</div>
              <div className="card-body text-dark">
                <h5 className="card-title">Dark card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        </div> */}
                <div className="align-items-center mt-5 row">
                    <Slide bottom>
                        <div className="col-md-4 col-sm-6 item">
                            <div className="card item-card card-block">
                                <h4 className="item-card-title text-center">
                                    <i className="fa fa-edit" /> Enter
                                </h4>
                                <img src={HowItWorks1} alt="Photo of sunset" />
                                <h5 className="card-title  mt-3 mb-3">Enter your information</h5>
                                <p className="card-text text-muted">Enter your information with only relevant information</p>
                            </div>
                        </div>
                    </Slide>

                    <Slide bottom>
                        <div className="col-md-4 col-sm-6 item">
                            <div className="card item-card card-block">
                                <h4 className="item-card-title text-center">
                                    <i className="fa fa-search" /> Preview
                                </h4>
                                <img src={HowItWorks2} alt="Photo of sunset" />
                                <h5 className="card-title  mt-3 mb-3">Preview your stub</h5>
                                <p className="card-text text-muted">Select stub template from a wide range of templates</p>
                            </div>
                        </div>
                    </Slide>

                    <Slide bottom>
                        <div className="col-md-4 col-sm-6 item">
                            <div className="card item-card card-block">
                                <h4 className="item-card-title text-center">
                                    <i className="fa fa-download" /> Download
                                </h4>
                                <img src={HowItWorks3} alt="Photo of sunset" />
                                <h5 className="card-title  mt-3 mb-3">Download your stub</h5>
                                <p className="card-text text-muted">Download you stub at your convenience</p>
                            </div>
                        </div>
                    </Slide>
                </div>
            </div>
        );
    }
}

export default HowItWorks;
