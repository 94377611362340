import { Typography } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import React from 'react';

export default function About() {
    return (
        <div className="PayStubForm">
            <div className="row myProgressBar">
                <div className="col-sm-12">
                    <h2 className="mb-3" style={{ textDecoration: 'underline' }}>
                        About Us
                    </h2>
                </div>
            </div>

            <div className="row progressBar" style={{ borderRadius: 10 }}>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>Who we are</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    Dr.Paystub Corp was founded and built by American Inventor/Businessman Mike “Diego” Usoro and Diego Enterprises, Inc’s vast experienced accountants in order to
                    offer its clients the ability to create their own paycheck stubs and payroll system.
                </Typography>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bolder', fontFamily: 'sans-serif', fontSize: 20 }}>Our services</Typography>
                </div>
                <Typography style={{ padding: 25 }}>
                    Our Dr. Paystub “Saurellius Tax API” is utilized to provide proof of income and a payroll stubs to employees by small business owners and contractors. Our
                    Saurellius Tax API calculations are validated by our professional accountant partners and according to the Internal Revenue Code (IRC), enacted by Congress in
                    Title 26 of the United States Code (26 U.S.C.). Since launch, we have had a massive amount of satisfied users that utilize our service. If you have any further
                    questions, do not hesitate to contact us.
                </Typography>
                <hr
                    style={{
                        color: 'black',
                        backgroundColor: 'black',
                        height: 1,
                        width: '100%',
                    }}
                />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button className="btn btn-secondary" style={{ width: '30%' }} href="./paystubs">
                        Create your Paystub now
                    </Button>
                </div>
            </div>
        </div>
    );
}
