import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { axios } from "../../../HelperFunctions/axios";
import DashboardLayout from "./layout/DashboardLayout";
import actionCreater from "../../../redux/actions/actionCreater";
import { connect } from "react-redux";
import placeholder from "../../../assets/placeholder.jpg";
import "../Dashboard/layout/styles.css";
import { Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useForm } from "react-hook-form";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: this.props.userData?.phoneNumber || "", // Set default value
      loading: false,
      file: null,
    };
  }

  handleImageClick = () => {
    this.fileInput.click();
  };

  handleFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    try {
      let url = process.env.REACT_APP_BACKEND_URL_LOCAL;
      if (process.env.REACT_APP_MODE === "live") {
        url = process.env.REACT_APP_FRONTEND_URL_LIVE;
      }
      const formData = new FormData();
      formData.append("file", this.state.file);

      let image = this.props?.image ?? "";
      if (this.state.file) {
        const response = await axios.post(`${url}api/auth/upload`, formData);
        image = response.data.url; // Assuming the response has the image URL
      } else {
        image = this.props?.image ?? "";
      }
      const payload = {
        phoneNumber: this.state.phoneNumber || this.props.userData?.phoneNumber,
        firstName: this.state.firstName || this.props.userData?.firstName,
        lastName: this.state.lastName || this.props.userData?.lastName,
        image: image,
      };
      const token = localStorage.getItem("tokens");
      const response = await axios.put(`${url}api/auth/update-user`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.status === 200 && response.data.data) {
        this.props.getUserDataSuccess(response.data.data);

        this.props.history.push("/dashboard/profile");

        confirmAlert({
          title: response.data.message,
          buttons: [
            {
              label: "Close",
            },
          ],
        });
      } else {
        confirmAlert({
          title: response.data.message,
          buttons: [
            {
              label: "Close",
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }

    this.setState({ loading: false });
  };

  render() {
    const { userData } = this.props;
    const { file, phoneNumber } = this.state;

    return (
      <DashboardLayout>
        <div className="container h-100">
          <div className="row align-items-center h-100 justify-content-center">
            <div className="col-md-8 col-lg-8 col-xl-5">
              <form className="contact-form" onSubmit={this.handleSubmit}>
                <h2>Update profile</h2>

                <div className="form-group">
                  <label>Profile Image</label>
                  <span className="table-category-img">
                    <img
                      src={
                        file
                          ? URL.createObjectURL(file)
                          : userData?.image
                          ? userData?.image
                          : placeholder
                      }
                      alt="pics"
                      className="listImages"
                      onClick={this.handleImageClick}
                    />
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.handleFileChange}
                    ref={(input) => (this.fileInput = input)}
                    style={{ display: "none" }}
                  />
                </div>

                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    defaultValue={userData?.firstName}
                    name="firstName"
                    onChange={(e) =>
                      this.setState({
                        firstName: e.target.value,
                      })
                    }
                    placeholder="First Name"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    defaultValue={userData?.lastName}
                    onChange={(e) =>
                      this.setState({
                        lastName: e.target.value,
                      })
                    }
                    placeholder="Last Name"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    defaultValue={userData?.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="Email"
                    disabled={true}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Phone Number</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={
                      userData?.phoneNumber
                        ? userData?.phoneNumber
                        : phoneNumber
                    }
                    limitMaxLength={12}
                    min={10}
                    required
                    onChange={(value) => this.setState({ phoneNumber: value })}
                  />
                </div>

                <div className="text-center">
                  {this.state.loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-secondary btn-block"
                    >
                      Update
                    </button>
                  )}
                </div>
                <br />
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state?.userData,
});

export default connect(mapStateToProps, {
  getUserDataSuccess: actionCreater.getUserDataSuccess,
})(UserProfile);
