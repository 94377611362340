import React, { Component } from "react";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { axios } from "../../../HelperFunctions/axios";
import DashboardLayout from "./layout/DashboardLayout";
import actionCreater from "../../../redux/actions/actionCreater";
import { connect } from "react-redux";
import placeholder from "../../../assets/placeholder.jpg";
import "../Dashboard/layout/styles.css";
import Table from "../Table/Table";
import moment from "moment";
import { Spinner } from "react-bootstrap";

class ViewPaystub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        company_name: "",
        company_address: "",
        company_address_2: "",
        company_city: "",
        company_state: "",
        companyZipCode: "",
        company_phone: "",
        company_ein: "",
        emailAddress: "",
        employment_status: "",
        employee_name: "",
        employee_address: "",
        employee_address_2: "",
        employee_city: "",
        employee_state: "",
        employeeZipCode: "",
        employee_Id: "",
        maritial_status: "",
        noOfDependants: "",
        blindExemptions: "",
        ssid: "",
        hourly_rate: "",
        pay_frequency: "",
        hours_worked_per_payPeriod: "",
        check_number: "",
        startDate: "",
        pay_dates: [],
        actual_pay_dates: [],
        hire_date: "",
        template: "",
        EmployeeHiredIn2021: false,
        additions: [],
        deductions: [],
        check_numbers: [],
        hours_worked: [],
      },
      createdAt: "",
      zipsrc: "",
      isLoading: false, // Add loading state
    };
  }

  formatAndJoinDates = (dates) => {
    return dates
      .map((date) => moment(date, "DD-MM-YYYY").format("DD-MMM-YYYY"))
      .join(", ");
  };

  componentDidMount() {
    this.handleGetSinglePaystub();
  }
  // componentDidMount() {
  //   this.loadApp();
  // }

  handleGetSinglePaystub = async () => {
    this.setState({ isLoading: true });
    let url = process.env.REACT_APP_BACKEND_URL_LOCAL;
    if (process.env.REACT_APP_MODE === "live") {
      url = process.env.REACT_APP_FRONTEND_URL_LIVE;
    }
    const paystubId = this.props.match.params.id;

    var data = "";

    var config = {
      method: "get",
      url: `${url}api/auth/get-paystub/${paystubId}`,
      data: data,
    };
    try {
      const response = await axios(config);
      this.setState({ data: response.data.params });
      this.setState({
        createdAt: moment(response.data.createdAt).format("DD-MMM-YYYY"),
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false }); // Set loading state back to false
    }
  };

  loadApp = async () => {
    this.setState({ isLoading: true });

    let url = process.env.REACT_APP_BACKEND_URL_LOCAL;
    if (process.env.REACT_APP_MODE === "live") {
      url = process.env.REACT_APP_FRONTEND_URL_LIVE;
    }
    const { data } = await axios.post(`${url}api/paystub/getZip`, {
      paystubId: this.props.match.params.id,
      template: this.state.data.template,
    });
    this.setState({ isLoading: false });

    return data.zipSrc;
    // console.log(data, "ddddddddddddddddd");
    // this.setState({ zipsrc: data.zipSrc });
  };

  downloadZip = async () => {
    this.setState({ isLoading: true });

    let zipsrc = await this.loadApp();
    const [_, ziplink] = zipsrc.split("/");

    let link =
      process.env.REACT_APP_MODE === "developement"
        ? process.env.REACT_APP_BACKEND_URL_LOCAL
        : "https://www.drpaystub.net/";
    const zipLinkDown = link + ziplink;
    window.open(zipLinkDown);
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <DashboardLayout>
        <div className="container h-100">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2>View Paystub </h2>
            </div>

            <div className="col-12 col-md-6 smallBtn">
              {this.state.isLoading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <button
                  tabIndex={-1}
                  onClick={() => this.downloadZip()}
                  className="btn btn-block btn-secondary smallBtn"
                >
                  Download
                </button>
              )}
              {/* {this.state.isLoading && (
                <Spinner animation="border" variant="primary" />
              )} */}
            </div>
          </div>
          <div className="card shadow border-0 p-4 rounded add-user-form mt-4">
            <div className="flex flex-col gap-2">
              <span className="d-block mb-4 f-20">
                <b>Company Details :-</b>
              </span>
              <div className="row">
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left">
                    <span>Name </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.company_name}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>Address </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.company_address}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>Second Address </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.company_address_2}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>City </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.company_city}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left  semi-bold  mb-1">
                    <span>State </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.company_state}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Zip Code </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.companyZipCode}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>EIN </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.company_ein}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Pay Dates </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.formatAndJoinDates(this.state.data.pay_dates)}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Registration Date </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.createdAt}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Start Date </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {moment(this.state.data.startDate, "MM-DD-YYYY").format(
                      "DD-MMM-YYYY"
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Email Address </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    <p className="contentdd">
                      <span className="activeDot">
                        <i className="fa-solid fa-circle-dot"></i>{" "}
                        {this.state.data.emailAddress}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <span className="d-block mb-4 f-20">
                <b>Employee Details :-</b>
              </span>
              <div className="row">
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left">
                    <span>Name </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.employee_name}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>Address </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.employee_address}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>Second Address </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.employee_address_2}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>City </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.employee_city}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left  semi-bold  mb-1">
                    <span>State </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.employee_state}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Zip Code </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.employeeZipCode}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>EIN </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.employee_name}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Status </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.employment_status}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Maritial Status </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.maritial_status}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Hire Date </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.hire_date}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>No. of dependants</span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.noOfDependants}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Blind Exemptions</span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.blindExemptions}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>SSID </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.ssid}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Annual Salary</span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.annual_salary}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Pay Frequency</span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.pay_frequency}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Check Number</span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.check_number}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <span className="d-block mb-4 f-20">
                <b>Bank Details :-</b>
              </span>
              <div className="row">
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left">
                    <span>Name </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.bank_name}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>Street Address </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.bank_street_address}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>City </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.bank_city}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold mb-1">
                    <span>State </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.bank_state}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Zip Code </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.bank_zip}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="driver-left semi-bold  mb-1">
                    <span>Manager </span>
                  </div>
                  <div className="driver-rghtt f-14">
                    {this.state.data.manager}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  paystubData: state?.paystubData,
});

export default connect(mapStateToProps, {
  getPaystubData: actionCreater.getPaystubData,
})(ViewPaystub);
