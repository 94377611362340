import React, { Component } from "react";
import { Slide } from "react-reveal";
import "./SingleBlog.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import { axios } from "../../../HelperFunctions/axios";
const format1 = "MM/DD/YYYY";

export class SingleBlog extends Component {
  state = {
    blog: {
      id: null,
      title: "",
      created_at: "",
      body_html: "",
      blog_id: null,
      author: "",
      user_id: null,
      published_at: null,
      updated_at: "",
      summary_html: "",
      template_suffix: "",
      handle: "",
      tags: "",
      admin_graphql_api_id: "",
      image: {
        created_at: "",
        alt: "",
        width: null,
        height: null,
        src: "",
      },
    },
  };
  componentDidMount() {
    const articleId = this.props.match.params.id;
    axios
      .get(`/api/blogs/${articleId}`)
      .then((res) => {
        this.setState({ blog: res.data.article });
      })
      .catch((err) => {
        console.log(err);
      });
      window.scrollTo(0, 0);
  }
  singleBlogComponent = () => {
    let tagArr = this.state.blog.tags ? this.state.blog.tags.split(",") : [];
    return (
      <div name="blog" className="mt-4 pt-2 col-md-12 col-lg-12">
        <div className="blog rounded border-0 shadow card">
          <div className="position-relative">
            <img
              src={this.state.blog.image ? this.state.blog.image.src : ""}
              alt=""
              className="rounded-top card-img-top"
            />
            <div className="overlay rounded-top bg-dark"></div>
          </div>
          <div className="content card-body">
            <h5 className="card-title title text-dark">
              {this.state.blog.title}
            </h5>
          </div>

          <div className="blog-content">{parse(this.state.blog.body_html)}</div>
          <div className="blog-footer">
            <p className="d-flex">
              <span className="user mr-3">
                <i className="fa fa-user"></i> {this.state.blog.author}
              </span>
              <span className="date mr-3">
                <i className="fa fa-calendar"></i>{" "}
                {this.state.blog.updated_at
                  ? moment(this.state.blog.updated_at).format(format1)
                  : ""}
              </span>
              {/* {tagArr.length > 0 ? (
                <span>
                  <span className="text-primary text-strong">Tags:</span>
                  {
                    <ul>
                      {tagArr.map((tag) => {
                        return <li className="tag">{tag.trim()}</li>;
                      })}
                    </ul>
                  }
                </span>
              ) : null} */}
            </p>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className="container singleBlog">
        <div className="row">{this.singleBlogComponent()}</div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <Slide bottom>
              <div className="text-center mt-5">
                <Link to="/blogs">
                  <button className="btn btn-secondary">Read More blogs</button>
                </Link>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    );
  }
}

export default SingleBlog;
