import React, { Component } from "react";
import { Zoom, Reveal, Slide } from "react-reveal";
import { Link } from "react-router-dom";

import "./Testimonials.scss";
import Img from "../../assets/bg.png";

export class Testimonials extends Component {
  render() {
    return (
      <div className="testimonials container">
        <div className="row">
          <div className="col-sm-12">
            <Slide bottom>
              <h1 className="text-center mb-4">
                See What Our Clients Said About Dr.Paystub
              </h1>
            </Slide>
            <p className="text-center text-muted para-desc mx-auto mb-0">
              We are serving our clients with the highly reliable pay stubs at{" "}
              <span className="text-primary font-weight-bold">Dr.Paystub</span>
            </p>
          </div>
        </div>
        <div className="justify-content-center row">
          <div className="mt-4 col-lg-12">
            <Slide bottom>
              <p className="text-center testimonial-body">
                <i className="fa fa-quote-left pr-3" aria-hidden="true"></i>
                I can guarantee you the satisfaction my company and I felt when we realized we didn’t have to shell out exponential amounts for software. This website does it all for us. Grateful.
                <br/>
                  - <span className="text-primary">Abraham Weitsman, New York</span>
              </p>
            </Slide>
            <Slide bottom>
              <p className="text-center testimonial-body">
                <i className="fa fa-quote-left pr-3" aria-hidden="true"></i>
                Sign me up! Fastest paystub generator ever!
                  <br/>
                  - <span className="text-primary">Raphael Dominguez, Houston</span>
              </p>
            </Slide>
            <Slide bottom>
              <p className="text-center testimonial-body">
                <i className="fa fa-quote-left pr-3" aria-hidden="true"></i>I
                never imagined setting up my payroll documentation in such an
                easy manner. I was literally able to issue out paychecks and
                paystubs for my team within minutes! And ACCURATE! My god!
                <br/>
                  - <span className="text-primary">Evan Price, Los Angeles</span>
              </p>
            </Slide>
            <Slide bottom>
              <div className="text-center mt-5">
                <Link to="/reviews">
                  <button className="btn btn-secondary">Show more reviews</button>
                </Link>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonials;
