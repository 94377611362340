import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { axios } from "../../../HelperFunctions/axios";
import CheckoutForm from "./CheckoutForm";
const {
  REACT_APP_STRIPE_LIVE_KEY,
  REACT_APP_STRIPE_TEST_KEY,
  REACT_APP_STRIPE_MODE,
} = process.env;
const stripeKey =
  REACT_APP_STRIPE_MODE === "dev"
    ? REACT_APP_STRIPE_TEST_KEY
    : REACT_APP_STRIPE_LIVE_KEY;

const stripePromise = loadStripe(stripeKey);

export const Checkout = ({
  amount,
  paystubId,
  template,
  setPaymentStatus,
  paydates,
}) => {
  const [secret, setSecret] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [noCoupon, setNoCoupon] = useState(false);

  const loadApp = async () => {
    const { data } = await axios.get(
      `/api/paystub/payment-intent?amount=${amount}`
    );
    setSecret(data.secret);
  };

  const handleCoupon = async () => {
    if (coupon === "DIEGO911X") {
      // await getZipLink();
      setPaymentStatus("completed");
    } else {
      setCoupon("");
      setNoCoupon(true);
    }
  };

  useEffect(() => {
    if (amount) {
      loadApp();
    }
  }, [amount]);

  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-6">
          {secret && (
            <Elements
              stripe={stripePromise}
              options={{
                // passing the client secret obtained from the server
                clientSecret: secret,
              }}
            >
              <CheckoutForm
                paystubId={paystubId}
                template={template}
                secret={secret}
              />
            </Elements>
          )}
        </div>
        <div className="col-12 col-sm-6 order-md-2 my-4">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">Your cart</span>
            <span className="badge badge-secondary badge-pill">1</span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">AstroSync Payroll Stub </h6>
                <small className="text-muted">Quantity - {paydates}</small>
              </div>
              <span className="text-muted">${amount}</span>
            </li>

            {/* <li className="list-group-item d-flex justify-content-between bg-light">
              <div className="text-success">
                <h6 className="my-0">Promo code</h6>
                <small>DIEGO</small>
              </div>
              <span className="text-success">-$30</span>
            </li> */}
            <li className="list-group-item d-flex justify-content-between">
              <span>Total (USD)</span>
              <strong>${amount}</strong>
            </li>
          </ul>

          <div className="card p-2">
            <div className="input-group">
              <input
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Promo code"
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCoupon}
                >
                  Redeem
                </button>
              </div>
            </div>
          </div>
          {noCoupon && <p className="text-danger">No coupon found!</p>}
        </div>
      </div>
    </div>
  );
};
