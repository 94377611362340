import React, { useEffect, useState } from "react";
import "./success.css";
import { Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import { axios } from "../../../HelperFunctions/axios";
import animation from "../../../assets/success-white.gif";
import animationJson from "../../../assets/success.json";
import loadingJson from "../../../assets/loading.json";
import Lottie from "react-lottie";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Success = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [zip, setZip] = useState("");
  const { id } = useParams();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsLoading = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  let query = useQuery();
  const template = query.get("template");

  const loadApp = async () => {
    const { data } = await axios.post("/api/paystub/getZip", {
      paystubId: id,
      template,
    });
    setZip(data.zipSrc);
    setIsSuccess(true);
  };
  useEffect(() => {
    if (id && template) {
      loadApp();
    }
  }, [id, template]);

  const downloadZip = () => {
    const [_, ziplink] = zip.split("/");

    let link =
      process.env.REACT_APP_MODE === "developement"
        ? process.env.REACT_APP_BACKEND_URL_LOCAL
        : "https://www.drpaystub.net/";
    const zipLinkDown = link + ziplink;
    window.open(zipLinkDown);
  };

  return (
    <div className="payement-section bg-white">
      <div className="row">
        <div className="col-12 col-md-6 mx-auto my-5">
          <div className="paymentComplete pt-5 rounded">
            {isSuccess ? (
              <Lottie options={defaultOptions} height={150} width={150} />
            ) : (
              <Lottie
                options={defaultOptionsLoading}
                height={200}
                width={200}
              />
            )}
            <p>Payment is complete!</p>
            {isSuccess && (
              <Button
                onClick={downloadZip}
                className="btn btn-secondary mt-4 mb-5"
              >
                Click here to Download
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
